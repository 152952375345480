import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import './css/bootstrap.min.css'
import './css/box-slider.css'
// import './css/owl.carousel.min.css'
import './css/style.css'
import './css/responsive.css'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

