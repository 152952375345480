import React, { Suspense, useEffect, useState } from "react";
import NavBar from "../component/NavBar";
import Footer from "../component/Footer";

function Home(props) {
  const [toggle, setToggle] = useState(0);
  const [showSlide, setShowSlide] = useState(false)
  
  useEffect(() => {
    const externalJS = (path) => {
      const script = document.createElement("script");
      script.src = path;
      script.async = true;
      document.body.appendChild(script);
    }
    externalJS("/assests/js/script.js")

  }, [])

  useEffect(() => {
    if (!toggle) return setShowSlide(true)
    return setShowSlide(false)
  }, [toggle])

  return (
    <React.Fragment>
      <NavBar />

      {/* Top section Start */}
      <div className="main-home-bg-section">
        <div className="container">
          <div className="main-bg-section">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="left-content-sec">
                  <h1>
                    HIDDEN
                <br />
                BEYOND
              </h1>
                  <p>
                    Hidden Beyond is a <a style={{color: '#F5FF00'}}>Free-to-Play</a> action-adventure
                game where players team up or play solo matches to level up
                their skills to find the hidden gemstones and take control over
                the Beyondverse.
              </p>
                  <div className="top-main-button">
                  <a href="https://youtu.be/crdgLiB_n4c" target="_blank" className="btn border-btn">
                   WATCH TRAILER
                  </a>
                  <a href="#"><button className="btn yellow-bg-btn">ADD TO WISHLIST</button>
                  </a>
                  {/* InnMind Widget */}
                  <a href="https://app.innmind.com/viewStartup/aEHzm2M8mKLND5Tcw" target="_blank">
                    <img src="https://app.innmind.com/images/widgets/innmind/InnMind-v3.png" className="top-main-button" height="50px" width="auto"/>
                  </a>

                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="right-vector-sec">
                  <img className="vector-img" src={require("../Images/character.png")} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="top-slider-bottom-big-img">
      <img className="shap-img-top" src={require("../Images/content-top-shap.png")} alt="" />
      <div className="set-height main-big-video" id="set-height">
      <video autoPlay loop muted>
      <source src="https://videohosturl.s3.ap-south-1.amazonaws.com/bg-main.mp4" type="video/mp4" />
       </video>
       </div>
      </div>

      {/* Top section End */}
      {/* Awards Start */}
      <div className="awards-sec">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="center-title">
                <h2>Web3 Asia Awards 2023 Winner</h2>
              </div>
              <div className="left-award-img">
                <img src={require("../Images/awards.png")} className="img-fluid" />
              </div>
              <div className="center-awards-text">
                <div className="desc-icons">
                  <div className="coming-soon">
                    <h6>Coming soon on</h6>
                  </div>
                  <img src={require("../Images/awards-icon.png")} className="img-fluid" />
                </div>
                <div className="center-desc-pera">
                  <p>
                    Join the Hidden Beyond community and be the first to know the
                latest news and updates. <a style={{color: '#F2AA4C'}}>Team Up</a> and take control
                of the world.
              </p>
                </div>
              </div>
              <div className="center-title">
                <h2><a style={{color: '#F2AA4C'}}>BNB Chain Hackathon Grants Winner</a></h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Awards End */}
      {/* Animation 1 Start */}
      <div className="animation-section dark-animation first-animation">
        <div className="right-video-animation">
          <div className="text-section mobile-sec">
            <h1>immersive world</h1>
          </div>
          <div className="text-section desktop-sec">
            <h1>immersive world</h1>
            <p>
              Free roam and explore the <a style={{color: '#F5FF00'}}>Metaverse.</a> Become the
          landlords and earn passive rewards.
        </p>
        <a href="#"><button className="btn yellow-bg-btn">ADD TO WISHLIST</button></a>
          </div>
          <div className="text-animation-sec-right">
            <div className="bg-ring">
              <img src={require("../Images/ring-white.png")} className="img-fluid" />
              <div className="video-animation-sec">
                <div className="set-height" id="set-garden">
                <video autoPlay loop muted>
                 <source src="https://videohosturl.s3.ap-south-1.amazonaws.com/bgv-1.mp4" type="video/mp4"
                 />
                 </video>
                  </div>
              </div>
            </div>
          </div>
          <div className="text-section mobile-sec">
            <p>
            Free roam and explore the <a style={{color: '#F5FF00'}}>Metaverse.</a> Become the
          landlords and earn passive rewards.
        </p>
        <a href="#"><button className="btn yellow-bg-btn">ADD TO WISHLIST</button></a>
          </div>
        </div>
      </div>
      {/* Animation 1 End */}
      {/* Animation 2 Start */}
      <div className="animation-section light-animation second-animation">
        <div className="left-video-animation">
          <div className="text-section mobile-sec">
            <h1>be a true owner</h1>
          </div>
          <div className="text-section desktop-sec">
            <h1>be a true owner</h1>
            <p>
              Say <a style={{color: '#F5FF00'}}> BYE</a> to unlimited NFTs and token minting. Own the
          in-game NFT items and Metaverse properties that has fixed supplies.
        </p>
        <a href="https://t.me/HiddenBeyondXYZ"target="_blank"><button className="btn border-btn-dark">JOIN GROUP</button></a>
          </div>
          <div className="text-animation-sec-left">
            <div className="bg-ring">
              <img src={require("../Images/ring.png")} className="img-fluid" />
              <div className="video-animation-sec">
                <div className="slider-sec">
                  <div className="owl-carousel owl-carousel-single">
                    <div>
                      <img className="item" data-dots={1} src={require("../Images/slide1.png")} />
                    </div>
                    <div>
                      <img className="item" data-dots={1} src={require("../Images/slide2.png")} />
                    </div>
                    <div>
                      <img className="item" data-dots={1} src={require("../Images/slide3.png")} />
                    </div>
                    <div>
                      <img className="item" data-dots={1} src={require("../Images/slide4.png")} />
                    </div>
                    <div>
                      <img className="item" data-dots={1} src={require("../Images/slide5.png")} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-section mobile-sec">
            <p>
              Say <a style={{color: '#F5FF00'}}> BYE</a> to unlimited NFTs and token minting. Own the
          in-game NFT items and Metaverse properties that has fixed supplies.
        </p>
        <a href="https://t.me/HiddenBeyondXYZ"target="_blank"><button className="btn border-btn-dark">JOIN GROUP</button></a>
          </div>
        </div>
      </div>
      {/* Animation 2 End */}
      {/* Animation 3 Start */}
      <div className="animation-section dark-animation third-animation">
        <div className="right-video-animation">
          <div className="text-section mobile-sec">
            <h1>play-and-earn</h1>
          </div>
          <div className="text-section desktop-sec">
            <h1>play-and-earn</h1>
            <p>
              Completely <a style={{color: '#F5FF00'}}>re-designed game mechanics</a> and rewarding
          system. Enter the tournament and loot the reward pool.
        </p>
        <a href="#"><button className="btn yellow-bg-btn">ADD TO WISHLIST</button></a>
          </div>
          <div className="text-animation-sec-right">
            <div className="bg-ring">
              <img src={require("../Images/ring-white.png")} className="img-fluid" />
              <div className="video-animation-sec">
                <div className="set-height" id="set-gun-men">
                  <video
                  autoPlay loop muted>
                  <source src="https://videohosturl.s3.ap-south-1.amazonaws.com/bgv-2.mp4" type="video/mp4"
                    
                    />
                  </video>
                </div>
              </div>
            </div>
          </div>
          <div className="text-section mobile-sec">
            <p>
              Completely <a style={{color: '#F5FF00'}}>re-designed game mechanics</a> and rewarding
          system. Enter the tournament and loot the reward pool.
        </p>
        <a href="#"><button className="btn yellow-bg-btn">ADD TO WISHLIST</button></a>
          </div>
        </div>
      </div>
      {/* Animation 3 End */}
      {/* Animation 4 Start */}
      <div className="blinking-animation">
        <div className="container-fluid">
          <div className="custom-player-sec">
            <div className="left-sec mobile-sec">
              <div className="text-section">
                <h1>custom player character</h1>
              </div>
            </div>
            <div className="left-sec desktop-sec">
              <div className="text-section">
                <h1>custom player character</h1>
                <p>
                  Create a personalized custom character in the Beyondverse. Own
              the character with <a style={{color: '#F2AA4C'}}>Ready Player Me ID</a> and use it
              across all Metaverse.
            </p>
                <img src={require("../Images/ready-player-me.png")} className="img-fluid" />
              </div>
            </div>
            <div className="right-sec">
              <div className="square-section">
                <img
                  src={require("../Images/readyplayerme-avatar.png")}
                  className="img-fluid .shadowanimation"
                />
              </div>
            </div>
            <div className="left-sec mobile-sec">
              <div className="text-section">
                <p>
                  Create a personalized custom character in the Beyondverse. Own
              the character with <a style={{color: '#F2AA4C'}}>Ready Player Me ID</a> and use it
              across all Metaverse.
            </p>
                <img src={require("../Images/ready-player-me.png")} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Animation 4 End */}
      {/* Box slider section Start */}
      <div className="box-slider-sec">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="title-sec text-center">
                <h1>Our Journey</h1>
              </div>
              <div className="slider-sec">
              <div className={showSlide ? "options box-slider-default" : "options"}>
                  <div onClick={() => setToggle((t) => t === 1 ? 0 : 1)} className={toggle === 1 ? "option slider-close active" : "option slider-close slide1"}>
                    <div  className="slide-sec-part">
                      <div className="top-main-section">
                        <h1>Phase 01</h1>
                        <img src={require("../Images/land1.png")} className="img-fluid" />
                      </div>
                      <div className="list-question">
                        <h6>Q4 2021</h6>
                        <ul>
                          <li>Concept of open-world and GameFi</li>
                          <li>Evaluating mechanism</li>
                          <li>Designing the open-world model into a Metaverse</li>
                          <li>Evaluating token mechanic and integration</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div onClick={() => setToggle((t) => t === 2 ? 0 : 2)} className={toggle === 2 ? "option slider-close active" : "option slider-close slide2"}>
                    <div className="slide-sec-part">
                      <div className="top-main-section">
                        <h1>Phase 02</h1>
                        <img src={require("../Images/land2.png")} className="img-fluid" />
                      </div>
                      <div className="list-question">
                        <h6>Q1-Q4 2022</h6>
                        <ul>
                          <li>Launching website and social media profiles</li>
                          <li>Acquiring Unreal Engine assets for environment testing</li>
                          <li>Releasing whitepaper and documents</li>
                          <li>Releasing stylized version of environment build - Prototype v1</li>
                          <li>Initial marketing and community activities</li>
                          <li>Releasing Play-and-Earn system shooter game - Prototype v1</li>
                          <li>Enhancing stylized environment of prototype v1</li>
                          <li>Showcasing in-game NFT skins</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div onClick={() => setToggle((t) => t === 3 ? 0 : 3)} className={toggle === 3 ? "option slider-close active" : "option slider-close slide3"}>
                    <div  className="slide-sec-part">
                      <div className="top-main-section">
                        <h1>Phase 03</h1>
                        <img src={require("../Images/land3.png")} className="img-fluid" />
                      </div>
                      <div className="list-question">
                        <h6>Q1-Q4 2023</h6>
                        <ul>
                          <li>Showcasing a graphically improved Metaverse - Prototype v2</li>
                          <li>Shooter gameplay trailer</li>
                          <li>Releasing solo shooter game - Prototype v2</li>
                          <li>Live showcase of shooter game in the events</li>
                          <li>Building the base version of the main game</li>
                          <li>Designing the map for the main game</li>
                          <li>Designing the Metaverse ecosystem for the main game</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div onClick={() => setToggle((t) => t === 4 ? 0 : 4)} className={toggle === 4 ? "option slider-close active" : "option slider-close slide4"}>
                    <div  className="slide-sec-part">
                      <div className="top-main-section">
                        <h1>Phase 04</h1>
                        <img src={require("../Images/land4.png")} className="img-fluid" />
                      </div>
                      <div className="list-question">
                        <h6>2024-2025</h6>
                        <ul>
                          <li>Closed alpha game release</li>
                          <li>Integrating testnet on the main game</li>
                          <li>Beta game release</li>
                          <li>Launching season 1 NFT skin packs</li>
                          <li>Game launch</li>
                          <li>Token Generation Event - Public launch</li>
                          <li>Mega tournament announcement</li>
                          <li>Releasing marketplace for the in-game NFT skins</li>
                          <li>Open land and property sale on the Metaverse</li>
                          <li>Sponsored advertisement and promotion in the Metaverse</li>
                          <li>Deploying games for NFT projects under the Hidden Beyond Metaverse ecosystem </li>
                          <li>Expanding locations and deploying properties on the Metaverse</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Box slider section End*/}
      {/* Team Section Start */}
      <div className="team-section">
        <div className="container">
          <div className="title-sec text-left">
            <h1>Team</h1>
          </div>
        </div>
        <div className="container-fluid">
          <div className="all-team-member-sec">
          <div className="team-member">
            <div className="member-part">
            <a href="https://www.linkedin.com/in/muthu-selvan/" target="_blank"> <img src={require("../Images/team/t1.png")} className="img-fluid" /></a>
              </div>
              <a href="https://www.linkedin.com/in/muthu-selvan/" target="_blank"> <p className="e-name">Muthu Selvan</p>
              <p className="e-position">Founder and CEO</p> <p className="c-name">Boasting 2 triumphant exits and 6-year mastery of web3 technology</p> </a>
            </div>
            <div className="team-member">
              <div className="member-part">
              <a href="https://www.linkedin.com/in/aarthi-rajkumar/" target="_blank"> <img src={require("../Images/team/t2.png")} className="img-fluid" /></a>
              </div>
              <a href="https://www.linkedin.com/in/aarthi-rajkumar/" target="_blank"> <p className="e-name">Aarthi Rajkumar</p>
              <p className="e-position">Co-Founder and COO</p> <p className="c-name">Fusion of Capgemini, Bosch, web3 domain, team management</p> </a>
            </div>
            <div className="team-member">
              <div className="member-part">
              <a href="https://www.linkedin.com/in/thenameisbharath/" target="_blank"> <img src={require("../Images/team/t6.png")} className="img-fluid" /></a>
              </div>
              <a href="https://www.linkedin.com/in/thenameisbharath/" target="_blank"> <p className="e-name">Bharath Pinaboyina</p>
              <p className="e-position">CFO</p> <p className="c-name">Ex-Filecoin Orb, IPFS, Filecoin Virtual Machine </p></a>
            </div>
            <div className="team-member">
              <div className="member-part">
              <a href="https://www.linkedin.com/in/camarokalithasan/" target="_blank"> <img src={require("../Images/team/t3.png")} className="img-fluid" /></a>
              </div>
              <a href="https://www.linkedin.com/in/camarokalithasan/" target="_blank"> <p className="e-name">Camaro Kalithasan</p>
              <p className="e-position">CTO</p> <p className="c-name">6 years of expertise in UE, course director award in AI, and team instructor </p> </a>
            </div>
            <div className="team-member">
              <div className="member-part">
              <a href="https://www.linkedin.com/in/jatin-sharma-6984aa1a0/" target="_blank"> <img src={require("../Images/team/t4.png")} className="img-fluid" /></a>
              </div>
              <a href="https://www.linkedin.com/in/jatin-sharma-6984aa1a0/" target="_blank"> <p className="e-name">Jatin Sharma</p>
              <p className="e-position">Senior Game Developer</p> <p className="c-name">Ex-Rockstar employee, senior game developer, and adept designer</p> </a>
            </div>
            <div className="team-member">
              <div className="member-part">
              <a href="https://www.linkedin.com/in/madhan-kumar-9007ba225/" target="_blank"> <img src={require("../Images/team/t5.png")} className="img-fluid" /></a>
              </div>
              <a href="https://www.linkedin.com/in/madhan-kumar-9007ba225/" target="_blank"> <p className="e-name">Madhan Kumar</p>
              <p className="e-position">Senior Environmental Designer</p><p className="c-name">3D visionary artist who transforms ideas into immersive world</p> </a>
            </div>
            <div className="team-member">
              <div className="member-part">
              <a href="https://www.linkedin.com/in/chirag-gadia-91b354159/" target="_blank"> <img src={require("../Images/team/t7.png")} className="img-fluid" /></a>
              </div>
              <a href="https://www.linkedin.com/in/chirag-gadia-91b354159/" target="_blank"> <p className="e-name">Chirag Gadia</p>
              <p className="e-position">Growth Manager</p><p className="c-name">Web3 growth maestro specializing in marketing and vibrant communities</p></a>
            </div>
            <div className="team-member">
              <div className="member-part">
              <a href="https://www.linkedin.com/company/sparkouttech/" target="_blank"> <img src={require("../Images/team/t8.png")} className="img-fluid" /></a>
              </div>
              <a href="https://www.linkedin.com/company/sparkouttech/" target="_blank"> <p className="e-name">Sparkout Tech</p>
              <p className="e-position">Blockchain Backer</p><p className="c-name">Fueling our journey with unwavering support and expertise</p></a>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="slider-bottom-section">
                <div className="owl-carousel owl-carousel-multi">
                  <div>
                    <img
                      className="item"
                      data-dots={1}
                      src={require("../Images/bottom-slider/slide1.png")}
                    />
                  </div>
                  <div>
                    <img
                      className="item"
                      data-dots={1}
                      src={require("../Images/bottom-slider/slide2.png")}
                    />
                  </div>
                  <div>
                    <img
                      className="item"
                      data-dots={1}
                      src={require("../Images/bottom-slider/slide3.png")}
                    />
                  </div>
                  <div>
                    <img
                      className="item"
                      data-dots={1}
                      src={require("../Images/bottom-slider/slide4.png")}
                    />
                  </div>
                  <div>
                    <img
                      className="item"
                      data-dots={1}
                      src={require("../Images/bottom-slider/slide5.png")}
                    />
                  </div>
                  <div>
                    <img
                      className="item"
                      data-dots={1}
                      src={require("../Images/bottom-slider/slide6.png")}
                    />
                  </div>
                  <div>
                    <img
                      className="item"
                      data-dots={1}
                      src={require("../Images/bottom-slider/slide7.png")}
                    />
                  </div>
                  <div>
                    <img
                      className="item"
                      data-dots={1}
                      src={require("../Images/bottom-slider/slide8.png")}
                    />
                  </div>
                  <div>
                    <img
                      className="item"
                      data-dots={1}
                      src={require("../Images/bottom-slider/slide9.png")}
                    />
                  </div>
                  <div>
                    <img
                      className="item"
                      data-dots={1}
                      src={require("../Images/bottom-slider/slide10.png")}
                    />
                  </div>
                  <div>
                    <img
                      className="item"
                      data-dots={1}
                      src={require("../Images/bottom-slider/slide11.png")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Team Section End */}
      {/* Network Section Start */}
      <div className="network-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="title-sec text-center">
                <h1>Network</h1>
              </div>
            </div>
            <div className="col-md-2 col-3">
              <div className="bottom-logo-img">
                <img src={require("../Images/bottom-logo/bl1.png")} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-2 col-3">
              <div className="bottom-logo-img">
                <img src={require("../Images/bottom-logo/bl2.png")} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-2 col-3">
              <div className="bottom-logo-img">
                <img src={require("../Images/bottom-logo/bl3.png")} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-2 col-3">
              <div className="bottom-logo-img">
                <img src={require("../Images/bottom-logo/bl4.png")} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-2 col-3">
              <div className="bottom-logo-img">
                <img src={require("../Images/bottom-logo/bl5.png")} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-2 col-3">
              <div className="bottom-logo-img">
                <img src={require("../Images/bottom-logo/bl6.png")} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-2 col-3">
              <div className="bottom-logo-img">
                <img src={require("../Images/bottom-logo/bl7.png")} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-2 col-3">
              <div className="bottom-logo-img">
                <img src={require("../Images/bottom-logo/bl8.png")} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-2 col-3">
              <div className="bottom-logo-img">
                <img src={require("../Images/bottom-logo/bl9.png")} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-2 col-3">
              <div className="bottom-logo-img">
                <img src={require("../Images/bottom-logo/bl10.png")} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-2 col-3">
              <div className="bottom-logo-img">
                <img src={require("../Images/bottom-logo/bl11.png")} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-2 col-3">
              <div className="bottom-logo-img">
                <img src={require("../Images/bottom-logo/bl12.png")} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-2 col-3">
              <div className="bottom-logo-img">
                <img src={require("../Images/bottom-logo/bl13.png")} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-2 col-3">
              <div className="bottom-logo-img">
                <img src={require("../Images/bottom-logo/bl14.png")} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-2 col-3">
              <div className="bottom-logo-img">
                <img src={require("../Images/bottom-logo/bl15.png")} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-2 col-3">
              <div className="bottom-logo-img">
                <img src={require("../Images/bottom-logo/bl16.png")} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Network Section End */}


      <Footer />

    </React.Fragment >
  );
}

export default Home;
