/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function NavBar() {
  return (
    <React.Fragment>
      <header className="header-main-section">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-md">
            <div className="header-main-sec">
              <a className="navbar-brand" href="#">
                <img src={require("../Images/logo.png")} alt="Logo" />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#headercollapse"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div
                className="collapse navbar-collapse navbar-menu"
                id="headercollapse"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link" target="_blank" href="https://twitter.com/HiddenBeyondXYZ">
                      <svg
                        width={43}
                        height={43}
                        viewBox="0 0 24 24"
                        className="hover-svg-menu"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
                      </svg>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" target="_blank" href="https://t.me/HiddenBeyondXYZ">
                      <svg
                        width={40}
                        height={40}
                        viewBox="0 0 39 39"
                        className="hover-svg-menu"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M35.4338 7.93708C35.8765 7.75073 36.3611 7.68646 36.8372 7.75096C37.3132 7.81546 37.7632 8.00636 38.1404 8.30381C38.5176 8.60126 38.8082 8.99437 38.9819 9.44223C39.1557 9.89009 39.2062 10.3763 39.1282 10.8503L35.0647 35.4983C34.6705 37.8758 32.0619 39.2393 29.8814 38.055C28.0575 37.0642 25.3485 35.5377 22.9118 33.9449C21.6935 33.1476 17.9614 30.5945 18.4201 28.7778C18.8143 27.2244 25.0851 21.3871 28.6684 17.9167C30.0749 16.5532 29.4335 15.7667 27.7726 17.0208C23.6464 20.1348 17.0262 24.8701 14.8368 26.2031C12.9054 27.3785 11.8984 27.5791 10.6944 27.3785C8.49786 27.013 6.46074 26.4468 4.79807 25.757C2.55132 24.8253 2.66061 21.7365 4.79628 20.8371L35.4338 7.93708Z"
                        />
                      </svg>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" target="_blank" href="https://www.instagram.com/HiddenBeyond">
                      <svg
                        width={43}
                        height={43}
                        viewBox="0 0 39 39"
                        className="hover-svg-menu"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.31 0H27.69C33.93 0 39 5.07 39 11.31V27.69C39 30.6896 37.8084 33.5663 35.6874 35.6874C33.5663 37.8084 30.6896 39 27.69 39H11.31C5.07 39 0 33.93 0 27.69V11.31C0 8.3104 1.19159 5.43366 3.31262 3.31262C5.43366 1.19159 8.3104 0 11.31 0ZM10.92 3.9C9.05818 3.9 7.27262 4.63961 5.95611 5.95611C4.63961 7.27262 3.9 9.05818 3.9 10.92V28.08C3.9 31.9605 7.0395 35.1 10.92 35.1H28.08C29.9418 35.1 31.7274 34.3604 33.0439 33.0439C34.3604 31.7274 35.1 29.9418 35.1 28.08V10.92C35.1 7.0395 31.9605 3.9 28.08 3.9H10.92ZM29.7375 6.825C30.384 6.825 31.004 7.08181 31.4611 7.53893C31.9182 7.99605 32.175 8.61604 32.175 9.2625C32.175 9.90897 31.9182 10.529 31.4611 10.9861C31.004 11.4432 30.384 11.7 29.7375 11.7C29.091 11.7 28.471 11.4432 28.0139 10.9861C27.5568 10.529 27.3 9.90897 27.3 9.2625C27.3 8.61604 27.5568 7.99605 28.0139 7.53893C28.471 7.08181 29.091 6.825 29.7375 6.825ZM19.5 9.75C22.0859 9.75 24.5658 10.7772 26.3943 12.6057C28.2228 14.4342 29.25 16.9141 29.25 19.5C29.25 22.0859 28.2228 24.5658 26.3943 26.3943C24.5658 28.2228 22.0859 29.25 19.5 29.25C16.9141 29.25 14.4342 28.2228 12.6057 26.3943C10.7772 24.5658 9.75 22.0859 9.75 19.5C9.75 16.9141 10.7772 14.4342 12.6057 12.6057C14.4342 10.7772 16.9141 9.75 19.5 9.75ZM19.5 13.65C17.9485 13.65 16.4605 14.2663 15.3634 15.3634C14.2663 16.4605 13.65 17.9485 13.65 19.5C13.65 21.0515 14.2663 22.5395 15.3634 23.6366C16.4605 24.7337 17.9485 25.35 19.5 25.35C21.0515 25.35 22.5395 24.7337 23.6366 23.6366C24.7337 22.5395 25.35 21.0515 25.35 19.5C25.35 17.9485 24.7337 16.4605 23.6366 15.3634C22.5395 14.2663 21.0515 13.65 19.5 13.65Z" />
                      </svg>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link active-link" target="_blank" href="https://www.linkedin.com/company/yellowwhale">
                      <svg
                        width={43}
                        height={43}
                        viewBox="0 0 39 39"
                        className="hover-svg-menu"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M34.6667 0C35.8159 0 36.9181 0.456546 37.7308 1.2692C38.5435 2.08186 39 3.18406 39 4.33333V34.6667C39 35.8159 38.5435 36.9181 37.7308 37.7308C36.9181 38.5435 35.8159 39 34.6667 39H4.33333C3.18406 39 2.08186 38.5435 1.2692 37.7308C0.456546 36.9181 0 35.8159 0 34.6667V4.33333C0 3.18406 0.456546 2.08186 1.2692 1.2692C2.08186 0.456546 3.18406 0 4.33333 0H34.6667ZM33.5833 33.5833V22.1C33.5833 20.2267 32.8392 18.4301 31.5145 17.1055C30.1899 15.7808 28.3933 15.0367 26.52 15.0367C24.6783 15.0367 22.5333 16.1633 21.4933 17.8533V15.4483H15.4483V33.5833H21.4933V22.9017C21.4933 21.2333 22.8367 19.8683 24.505 19.8683C25.3095 19.8683 26.081 20.1879 26.6499 20.7568C27.2188 21.3256 27.5383 22.0972 27.5383 22.9017V33.5833H33.5833ZM8.40667 12.0467C9.37206 12.0467 10.2979 11.6632 10.9805 10.9805C11.6632 10.2979 12.0467 9.37206 12.0467 8.40667C12.0467 6.39167 10.4217 4.745 8.40667 4.745C7.43553 4.745 6.50417 5.13078 5.81748 5.81748C5.13078 6.50417 4.745 7.43553 4.745 8.40667C4.745 10.4217 6.39167 12.0467 8.40667 12.0467ZM11.4183 33.5833V15.4483H5.41667V33.5833H11.4183Z" />
                      </svg>
                    </a>
                  </li>
                </ul>
                <div className="right-logo">
                  <a className="nav-link" target="_blank" href="https://yellowwhale.ventures/">
                    <img src={require("../Images/right-logo.png")} alt="twitter" />
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>

    </React.Fragment>
  );
}

export default NavBar;
